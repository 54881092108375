/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
// .mat-simple-snackbar-action > button {
//     @apply bg-primary text-primary;
// }

pre {
  font-weight: 400;
  font-size: small;
  white-space: pre-wrap;
  word-break: break-word;

  .number-line {
    color: #adadaf;
  }
  .string {
    color: #228403;
  }
  .number {
    color: #fc526a;
  }
  .boolean {
    color: #0570de;
    font-weight: bold;
  }
  .null {
    color: #c84801;
  }
  .key {
    color: black;
  }
}

@mixin md-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  width: $size;
  min-width: $size;
  max-width: $size;
  line-height: $size;
}

.warn-snackbar {
  @apply bg-red-200 text-red-600 #{!important};
}

.tilled-card-styling {
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbars::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbars {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
